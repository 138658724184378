import React from 'react';
import { calculateTotalAmountFromEveryone } from './sushiro';
import './BillSummary.css';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import Badge from '@mui/material/Badge';

const BillSummary = ({ eachPersonBillSummary, SubTotal, serviceCharge, totalPriceWithServiceCharge, resetCalculator, undoLastAction, calculateNextPersonBill }) => {
    const [showTotalAmountFromEveryone, setShowTotalAmountFromEveryone] = React.useState(false);
    const clearPage = () => {
        resetCalculator();
        setShowTotalAmountFromEveryone(false);
    }
    const handleUndoLastAction = () => {
        if (eachPersonBillSummary.length === 1) {
            setShowTotalAmountFromEveryone(false);
        }
        undoLastAction();
    }
    const handlecalculateNextPersonBill = () => {
        calculateNextPersonBill();
        setShowTotalAmountFromEveryone(true);
    }
    const totalAmountFromEveryone = calculateTotalAmountFromEveryone(eachPersonBillSummary);
    return (
        <>
            <h2 id="totalPrice">Sub Total Price: {SubTotal.toLocaleString()} THB</h2>
            <h2 id="serviceCharge">Service Charge (10%): {serviceCharge.toLocaleString()} THB</h2>
            <h2 id="totalPriceWithServiceCharge">Total Price: {totalPriceWithServiceCharge.toLocaleString()} THB</h2>

            <h2 id="totalPrices"></h2>
            <div class="actionButtonClass">
                <div class="dish mobileNextPerson">
                    <Button variant="contained" size="large" onClick={() => clearPage()}>Clear</Button>
                    <Button variant="contained" size="large" onClick={() => handleUndoLastAction()}>Undo</Button>
                    <Button variant="contained" size="large" onClick={() => handlecalculateNextPersonBill()}>Cal Next Person</Button>
                    {/* กรอกเลขอาหารที่ไม่มีใน button เรา */}
                </div>
            </div>

            {showTotalAmountFromEveryone && (
                <div class="totalPersonClass">
                    <div id="totalPerson" >
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                                Total amount from everyone = {totalAmountFromEveryone.toLocaleString()} THB
                            </Alert>
                        </Stack>
                        <span style={{ marginRight: '15px' }}>  </span>
                        {eachPersonBillSummary.map(({ personName, quantities, onePersonBillSummary }) => {
                            return (<div>
                                <Badge
                                    badgeContent={`${personName}`}
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: '#dc3545',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    <FaceIcon sx={{ color: 'black' }} /> {/* Set the person icon to black */}
                                </Badge>
                                <span style={{ marginRight: '15px' }}>  </span>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ display: 'inline-flex', marginBottom: 2 }}
                                >
                                    <Button variant="contained" sx={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'darkgreen',
                                        },
                                    }} >{`${onePersonBillSummary.toLocaleString()}฿`}</Button>
                                </Stack>
                                {/* {`${personName}: ${onePersonBillSummary.toLocaleString()} THB`} */}
                            </div>
                            )
                        })}

                    </div>
                </div>
            )}
        </>
    )
}
export default BillSummary;