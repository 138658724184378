import React, { useEffect, useState } from 'react';
import './App.css';
import DishCalculator from './DishCalculator';
import BillSummary from './BillSummary';
import { calculateSubTotalAndServiceChargeAndTotalPriceWithServiceCharge } from './sushiro';
import { Helmet } from 'react-helmet';
import OpeningAnimation from './components/OpeningAnimation'; // Import the animation component

function App() {
  const [quantities, setQuantities] = useState([0, 0, 0, 0, 0, 0]);
  const [eachPersonBillSummary, setEachPersonBillSummary] = useState([]);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false); // Track animation status

  const { SubTotal, serviceCharge, totalPriceWithServiceCharge } =
    calculateSubTotalAndServiceChargeAndTotalPriceWithServiceCharge(quantities);

  const addDish = (index) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      newQuantities[index]++;
      return newQuantities;
    });
  };

  const minusDish = (index) => {
    setQuantities(prevQuantities => {
      const newQuantities = [...prevQuantities];
      if (newQuantities[index] > 0) {
        newQuantities[index]--;
      }
      return newQuantities;
    });
  };

  const resetCalculator = () => {
    setQuantities(x => new Array(x.length).fill(0));
    setEachPersonBillSummary([]);
  };

  const undoLastAction = () => {
    const lastPersonBillSummary = eachPersonBillSummary[eachPersonBillSummary.length - 1];
    if (!lastPersonBillSummary) {
      return
    }
    setEachPersonBillSummary(prev => prev.slice(0, prev.length - 1));
    setQuantities(lastPersonBillSummary.quantities);
  };

  const calculateNextPersonBill = () => {
    setEachPersonBillSummary(prev => {
      const personNumber = prev.length + 1;
      return [...prev,
      {
        personName: `${personNumber}`,
        quantities: quantities,
        onePersonBillSummary: totalPriceWithServiceCharge,
      }];
    });
    setQuantities(x => new Array(x.length).fill(0));
  };

  // Use useEffect to simulate animation completion
  useEffect(() => {
    setTimeout(() => {
      setIsAnimationComplete(true);
    }, 3000); // Simulating 3 seconds for the animation duration
  }, []);

  return (
    <div>
      <Helmet>
        <title>Panne - Sushiro</title>
      </Helmet>

      {/* Show animation only at the start */}
      {!isAnimationComplete && <OpeningAnimation />}

      {/* Main content appears after animation */}
      {isAnimationComplete && (
        <header>
          <div className="container">
            <img
              className="logo"
              src="https://cdn.glitch.global/87b890ca-6106-49ca-aa8a-61c4d3b89a53/SUSHIRO_logo_alphabet.png?v=1693466009771"
              alt="Logo"
            />
            <h1>Calculator</h1>
            <DishCalculator
              quantities={quantities}
              addDish={addDish}
              minusDish={minusDish}
            />
            <BillSummary
              SubTotal={SubTotal}
              serviceCharge={serviceCharge}
              totalPriceWithServiceCharge={totalPriceWithServiceCharge}
              eachPersonBillSummary={eachPersonBillSummary}
              resetCalculator={resetCalculator}
              undoLastAction={undoLastAction}
              calculateNextPersonBill={calculateNextPersonBill}
            />
          </div>
        </header>
      )}
    </div>
  );
}

export default App;
