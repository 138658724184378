import React, { useState } from 'react';
import { prices } from './sushiro'
import './DishCalculator.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';


const DishCalculator = ({ quantities, addDish, minusDish }) => {
    const [customPrice, setCustomPrice] = useState('');
    const [confirmedPrices, setConfirmedPrices] = useState([]);

    const handleCustomPriceChange = (e) => {
        const value = e.target.value;
        if (value === '' || value > 0) {
            setCustomPrice(value);
        }
    };

    const handleConfirmPrice = () => {
        if (customPrice && !confirmedPrices.includes(Number(customPrice))) {
            setConfirmedPrices([...confirmedPrices, Number(customPrice)]);
            const newPrice = Number(customPrice);
            prices.push(newPrice);
            quantities[prices.length - 1] = 0;
            setCustomPrice('');
        }
    };

    const isConfirmButtonDisabled = !customPrice || prices.includes(Number(customPrice));
    return (
        <div class="eachDishCenter">
            {prices.map((price, index) => {
                return (
                    <div class={`dish dish${price}`}>
                        <Button variant="contained" size="large" onClick={() => minusDish(index)}>
                            -{price}
                        </Button>
                        {/* <span class="dish-label">Quantity: <span id="dish1Quantity">{quantities[index]}</span></span> */}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                border: '1px solid #e0e0e0',
                                borderRadius: 2,
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    padding: '8px 16px',
                                    paddingTop: '14px',
                                    paddingBottom: '14px',
                                    width: "34px",
                                    textAlign: "right"
                                }}>
                                <Typography variant="body2">{price}฿</Typography>
                            </Box>
                            <Box
                                sx={{
                                    padding: '8px 16px',
                                    paddingTop: '14px',
                                    paddingBottom: '14px',
                                    width: '16px',
                                    height: '20px',
                                    backgroundColor: 'white'
                                }}>
                                <Typography variant="body2">{quantities[index]}</Typography>
                            </Box>
                        </Box>

                        <Button variant="contained" size="large" onClick={() => addDish(index)}>
                            +{price}
                        </Button>
                    </div>
                );
            })}
            {/* Custom price input section */}
            <div class="dish dishCustom">
                <Button
                    variant="contained"
                    size="large"
                    disabled
                >
                    -
                </Button>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #e0e0e0',
                        borderRadius: 2,
                        overflow: 'hidden',
                        flexDirection: 'column',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                padding: '8px 16px',
                                paddingTop: '3px',
                                paddingBottom: '7px',
                                width: "34px",
                                textAlign: "right",
                                display: "flex",
                                alignItems: "center"
                            }}>
                            <input
                                type="number"
                                value={customPrice}
                                onChange={handleCustomPriceChange}
                                style={{
                                    width: '100%',
                                    background: 'transparent',
                                    border: 'none',
                                    color: 'white',
                                    textAlign: 'right',
                                    padding: 0,
                                    margin: 0,
                                    fontSize: '0.875rem',
                                    fontFamily: 'inherit',
                                    outline: 'none'
                                }}
                                placeholder="฿"
                                min="0"
                            />
                        </Box>
                        <Box
                            sx={{
                                padding: '8px 16px',
                                paddingTop: '14px',
                                paddingBottom: '14px',
                                width: '16px',
                                height: '20px',
                                backgroundColor: 'white'
                            }}>
                            <Typography variant="body2">0</Typography>
                        </Box>
                    </Box>


                </Box>
                <Button
                    variant="contained"
                    size="large"
                    disabled
                >
                    +
                </Button>

            </div>
            <div>
                <Button
                    variant="contained"
                    size="small"
                    onClick={handleConfirmPrice}
                    disabled={isConfirmButtonDisabled}
                    sx={{ margin: '4px', minWidth: '80px', backgroundColor: '#dc3545'}}
                >
                    Confirm
                </Button>
            </div>
        </div>
    )
}

export default DishCalculator;