import React, { useEffect } from "react";
import { initOpeningAnimation } from "../animationsSushi/initopeningAnimation";
import "../components/stylesAnimation.css";
import "../components/styleGuide.css";
//import "../components/styleNew.css";

const OpeningAnimation = () => {
  useEffect(() => {
    initOpeningAnimation();
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-screen">
        <div className="loading-words">
          <h2 className="home-active home-active-first">Welcome</h2>
          <h2 className="home-active">to</h2>
          <h2 className="home-active jap">Calculator</h2>
          <h2 className="home-active">for</h2>
          <h2 className="home-active-last">SUSHIRO</h2>
        </div>
      </div>
    </div>
  );
};

export default OpeningAnimation;
