import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const initOpeningAnimation = () => {
  const tl = gsap.timeline();

  // Initial setup: hide and position elements
  tl.set(".loading-words", {
    opacity: 0,
    y: -50,
  });

  tl.set(".loading-words .home-active, .loading-words .home-active-last", {
    display: "block",
    opacity: 0,
  });

  tl.set(".loading-words .home-active-first", {
    opacity: 1,
  });

  // Fade in and move the entire text block
  tl.to(".loading-words", {
    duration: 0.8,
    opacity: 1,
    y: 0, // Adjusted for smoother entrance
    ease: "power4.out",
    delay: 0.5,
  });

  // Sequentially show and hide each word (staggered effect)
  tl.to(".loading-words .home-active", {
    duration: 0.01,
    opacity: 1,
    stagger: 0.15,
    ease: "none",
    onStart: () => {
      gsap.to(".loading-words .home-active", {
        duration: 0.01,
        opacity: 0,
        stagger: 0.15,
        ease: "none",
        delay: 0.15,
      });
    },
  });

  // Show the final word
  tl.to(".loading-words .home-active-last", {
    duration: 0.01,
    opacity: 1,
    delay: 0.15,
  });

  // Fade out the entire animation container
  tl.to(".loading-words", {
    duration: 0.3,
    opacity: 0,
    ease: "linear",
    onComplete: () => {
      // Optionally hide the container after animation
      document.querySelector(".loading-container").classList.add("loading-container-hidden");
    },
  });
};
